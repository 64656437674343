<template>
    <layout :hidecenter="true">
        <v-container class="loginpage mb-16">
            <breadcrumbs :items="breadcrumbs" :large="true" class="py-7" textcolor="textdark"></breadcrumbs>

            <v-card flat>
                <v-row>
                    <v-col cols="12" lg="7">
                        <v-card-text class="px-10 py-8">
                            <h1 class="text-28-60">Reset your password</h1>
                            <p class="pt-4">Enter e-mail address you used when creating your account</p>
                            <div class="">
                                <label for="" class="text-14-45">Email</label>
                                <v-text-field class="mt-2" v-model="form.email" placeholder="your@email.com" outlined background-color="silver"></v-text-field>
                            </div>
                            <v-btn x-large class="secondary py-8 text-14 text-transform-none w-100 mt-5" depressed>Reset password</v-btn>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" lg="5" class="primary px-0 py-0 d-flex">
                        <div class="p-relative w-100">
                            <img src="@/assets/icons/auth/reset.png" alt="" class="p-absolute b-0 l-0 w-100">
                        </div>
                    </v-col>

                </v-row>
            </v-card>
        </v-container>
    </layout>
</template>

<script>
import Layout from '@/components/layouts/LightTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'

export default {
    components: {
        Layout,
        Breadcrumbs
    },
    data: () => ({
        form:{
            email: '',
        },
        pass_show: false,
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Reset password',
                disabled: true,
            }
        ]
    }),
}
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';

.loginpage{
    .primary.col-12{
        border-radius: 0 10px 10px 0;
    }
    .v-text-field{
        fieldset{
            border: none;
        }
    }
    .v-btn.bg-transparent{
        border: 1px solid #CDE0FF;
    }
    .dotborder{
        border-bottom: 1px dashed #B9B9B9;
        height: 1px;
    }
    .bg-white-02{
        background-color: rgba(255,255,255,0.2)!important;
    }
    .rightblockimagetext{
        position: relative;
        z-index: 5;
    }
    .checkbox{
        .v-label{
            font-size: 14px;
            line-height: 20px;
            color: $dark;
        }
    }
}
</style>
